import { Routes, Route } from 'react-router-dom'
import { Home } from './components/Home';
import { About } from './components/About';
import { Navbar } from './components/Navbar';
import { Listing } from './components/Listing';
import { NotFound } from './components/NotFound';
import { ItemDetails } from './components/ItemDetails';

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="about" element={<About />}></Route>
        <Route path="listing" element={<Listing />}></Route>
        <Route path="*" element={<NotFound />}></Route>
        <Route path="listing/item/:itemId" element={<ItemDetails />}></Route>
      </Routes>
    </>
  );
}

export default App;
