import { ContentWrapper } from "./ContentWrapper"

const items = [
    {
        id: 1,
        name: 'Item 1',
    },
    {
        id: 2,
        name: 'Item 2',
    },
    {
        id: 3,
        name: 'Item 3'
    }
]

export const Listing = () => {
    return <ContentWrapper>
        <h1>Listing Page</h1>
        <div className="listing-items">
            {
                items.map((item, index) => {
                    return (
                        <a href={'/listing/item/' + item.id } key={index} className="listing-item">
                            <h3>{item.name}</h3>
                        </a>
                    )
                })
            }
        </div>
    </ContentWrapper>
}