import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/logo.svg'

export const Navbar = () => {
    return (
        <nav>
            <div className='inner-wrapper'>
                <div className="logo-wrapper">
                    <img alt="hive-logo" className="logo" src={logo} />
                </div>
                <div className="links-wrapper">
                    <Link className='link' to="/">Home</Link>
                    <Link className='link' to="/about">About</Link>
                    <Link className='link' to="/listing">Listing</Link>
                </div>
            </div>
        </nav>
    )
}