import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContentWrapper } from "./ContentWrapper";

export const ItemDetails = () => {
  const { itemId } = useParams();

  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`http://localhost:5000/itemDetails/${itemId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setDescription(data.description);
        setLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {
          setError(error.message);
          setLoading(false);
        }, 2000);
      });
  }, [itemId]);

  return (
    <ContentWrapper>
      <h1>Item</h1>
      <p>
        ID: <strong>{itemId}</strong>
      </p>
      <p>Description:</p>
      {loading ? (
        <span className="loading">Loading...</span>
      ) : error ? (
        <strong className="error">{error}</strong>
      ) : (
        <strong>{description}</strong>
      )}
    </ContentWrapper>
  );
};
