import { useNavigate } from "react-router-dom"
import { ContentWrapper } from "./ContentWrapper"

export const Home = () => {

    const navigate = useNavigate()

    const goToPage = (path) => {
        return () => {
            navigate(path)
        }
    }

    return <ContentWrapper>
        <h1>Home Page</h1>
        <button onClick={goToPage('about')} className="primaryButton">Go to About!</button>
    </ContentWrapper>
}